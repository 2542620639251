.course-enter {
  left: 500px;
}

.course-enter-done {
  left: 0 !important;
  transition: left 0.6s ease-in-out;
}

.rfq-incoming-expand-enter {
  height: 47px;
}
.rfq-incoming-expand-enter-done {
  height: 60px;
  transition: height 0.3s;
}
.rfq-incoming-expand-enter .rfq-incoming-inputs {
  opacity: 0;
}
.rfq-incoming-expand-enter-done .rfq-incoming-inputs {
  opacity: 1;
  transition: opacity 0.3s;
}

@keyframes slidein {
  from {
    transform: translateX(300px);
  }

  to {
    transform: translateX(-100%);
  }
}

.news-animation {
  position: absolute;
  animation-name: slidein;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes newsFlash {
  0% {
    color: #cca20e;
  }

  50% {
    color: #c03548;
  }

  100% {
    color: #cca20e;
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
