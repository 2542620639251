@font-face {
  font-family: Roboto;
  font-weight: 400;
  src: local("Roboto"), url("Regular_Roboto.ttf") format("truetype");
}

@font-face {
  font-family: Roboto;
  font-weight: 300;
  src: local("Roboto"), url("Thin_Roboto.ttf") format("truetype");
}

@font-face {
  font-family: Roboto;
  font-weight: 500;
  src: local("Roboto"), url("Medium_Roboto.ttf") format("truetype");
}

@font-face {
  font-family: Roboto;
  font-weight: 700;
  src: local("Roboto"), url("Bold_Roboto.ttf") format("truetype");
}

@font-face {
  font-family: SFProText;
  font-weight: 400;
  src: local("SFProText"), url("Regular_SFProText.ttf") format("truetype");
}

@font-face {
  font-family: IBMPlexSans;
  font-weight: 400;
  src: local("IBMPlexSans"), url("Regular_IBMPlexSans.ttf") format("truetype");
}

@font-face {
  font-family: IBMPlexSans;
  font-weight: 500;
  src: local("IBMPlexSans"), url("Medium_IBMPlexSans.ttf") format("truetype");
}

@font-face {
  font-family: IBMPlexSans;
  font-weight: 600;
  src: local("IBMPlexSans"), url("SemiBold_IBMPlexSans.ttf") format("truetype");
}

@font-face {
  font-family: IBMPlexSans;
  font-weight: 700;
  src: local("IBMPlexSans"), url("Bold_IBMPlexSans.ttf") format("truetype");
}
