html {
  overflow: hidden;
}

body {
  max-height: 100vh;
  margin: 0;
  font-family: IBMPlexSans, Roboto, SFProText, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .ScrollbarsCustom-Thumb {
  background: #2d363b !important;
}

.ScrollbarsCustom-Track {
  background: #141a1e !important;
  width: 6px !important;
}

.ScrollbarsCustom-Content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: calc(100vh - 448px);
  gap: 8px;
  padding-bottom: 16px;
} */

.react-toggle-track {
  width: 17px !important;
  height: 11px !important;
}

.react-toggle-thumb {
  width: 9px !important;
  height: 9px !important;
  background: #272e33 !important;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15), 0 3px 1px rgba(0, 0, 0, 0.06) !important;
  border: transparent !important;
}

.react-toggle--checked .react-toggle-track {
  background: #947c23 !important;
}

.react-toggle--checked .react-toggle-thumb {
  left: 7px !important;
  border: transparent !important;
}
